
<template>
  <v-container fluid fill-height class="pa-0 ma-0 narekomu-back">
    <div class="login-back people"></div>
    <v-card v-if="!isComplete && !isError" id="login-card" class="elevation-1 login-card body-2">
      <v-toolbar color="blue-grey darken-4" dark flat height="80" :loading="loading">
        解除中...
      </v-toolbar>
    </v-card>
    <v-card v-else-if="isError" class="login-card">
      <dialog-error text="URLが無効です" to-top="true" />
    </v-card>
    <v-card v-else class="login-card">
      <dialog-complete text="ロックを解除しました" to-top="true" />
    </v-card>
  </v-container>
</template>


<script>
import axios from 'axios';
import DialogComplete from '@/components/DialogComplete.vue';
import DialogError from '@/components/DialogError.vue';

export default {
  components: {
    DialogComplete,
    DialogError,
  },
  name: 'Unlock',
  data: () => ({
    loading: false,
    isComplete: false,
    isError: false,
  }),
  created() {
    this.unlock();
  },
  methods: {
    //アカウントロックの解除
    unlock() {
      this.loading = true;
      axios({
        method: 'PUT',
        url: `${process.env.VUE_APP_URL}unlock`,
        headers: {'X-Api-Key': process.env.VUE_APP_KEY, 'Content-Type': 'application/json'},
        data: {
          sign: this.$route.query.sign,
          token: this.$route.query.token,
        },
      })
        .then((response) => {
          if (response.data.results === 'complete') {
            this.isComplete = true;
            this.loading = false;
          } else {
            this.loading = false;
            this.isError = true;
          }
        })
        .catch((reason) => {
          console.log(reason);
          this.loading = false;
          this.isError = true;
        });
    },
  },
};

</script>

<style  scoped>
    .login-card {
        width:400px;
        margin:-250px auto 0 auto;
        z-index:2000;
    }

    .narekomu-back{
        /* background: url("../assets/login-narekomuvr.jpg"); */
        background-size: cover;
        background-position: center center;
        width: 100%;

    }

    #no-background-hover::before {
      background-color: transparent !important;
    }

.login-back{
      position:absolute;
       left: 0;
      right: 0;
      margin: auto;
      width:100%;
      max-width:1400px;
      height:100%;
    }

    .people{
      animation: fadein 0.4s ease-out forwards;
      background: url("~@/assets/people.webp") no-repeat center center;
      background-size:auto 100%;
    }

    .male{
      position:absolute;
  
      right:0%;
      height:100%;
      max-width:850px;
      min-width:100px;
      bottom:0;
      z-index:1000;
      object-fit:cover;
      object-position:right top;
    }

    .female{
      position:absolute;
      height:100%;
      left:0%;
      bottom:0;
      z-index:900;
      max-width:850px;
      min-width:100px;
      object-fit:cover;
      object-position:left top;
    }


    @media screen and (max-width: 1000px) {
      .male{
         position:absolute;
        height:100%;
        left:0;
        max-width:850px;
        min-width:100px;
        width:50%;
        bottom:0;
        z-index:1000;
        object-fit:cover;
        object-position:50% top;
      }

      .female{
        position:absolute;
        height:100%;
        left:50%;
        bottom:0;
       
        z-index:900;
        max-width:850px;
        min-width:100px;
        width:60%;
        object-fit:cover;
        object-position:50% top;
      } 

    }

</style>